// @ts-nocheck
// import React from "react";
import Navbar, { Nav } from "../../nav/Navbar";
import Brand from "../../brand/Brand";
import MenuContent from "./MenuContent";

// import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const menuContent = [
  {
    name: "Services",
    dropdownMenu: [
      {
        name: "Digital Strategy & Consulting",
        to: "services/digital-strategy-consulting",
      },
      {
        name: "Data & Artificial Intelligence",
        to: "services/data-artificial-intelligence",
      },

      {
        name: "Technology & Engineering",
        to: "services/technology-engineering",
      },
      {
        name: "Platforming & Cloud Services",
        to: "services/platforming-cloud-services",
      },
      //  {
      //   name: "Conversational AI Applications",
      //   to: "services/conversational-ai-applications",
      // },
      // {
      //   name: "Chatbots",
      //   to: "services/chatbots",
      // },
      // {
      //   name: "Happr AI Reports",
      //   to: "/reports",
      // },
    ],
  },
  {
    name: "Solutions",
    dropdownMenu: [
      { name: "RPDP", to: "/rpdp-rapid-product-development-platform" },
      { name: "Happr App (B2C)", to: "https://www.happrlife.com" },
    ],
  },

  // { name: "Reports", to: "/reports" },
  { name: "News & Insights", to: "/news-and-insights" },
  // { name: "Case Studies", to: "/case-studies" },
  // { name: "Stories", to: "/" },
  // { name: "Reports", to: "/reports" },
  // { name: "About", to: "/about" },
  { name: "Purpose", to: "/purpose" },
  { name: "Get in Touch", to: "/contact" },
];

const HapprMenu = ({ hamburger }) => {
  let $key = 0;
  const getSubMenu = (items) => {
    $key++;
    if (items.dropdownMenu) {
      return (
        <Nav.Dropdown name={items.name} key={$key}>
          {items.dropdownMenu.map((item) => getSubMenu(item))}
        </Nav.Dropdown>
      );
    }
    return (
      <Nav.Link
        to={items.name === "Stories" ? "https://stories.happr.ai" : items.to}
        key={$key}
        target={items.name.indexOf("Happr Way") !== -1 ? "_blank" : "_self"}
      >
        {items.name}
      </Nav.Link>
    );
  };

  return (
    <Navbar hamburger={hamburger}>
      <Navbar.Brand to={"/"}>
        <Brand width="40px" height="60px" />
      </Navbar.Brand>

      <Navbar.Collapse cover="Menu">
        <Nav>{menuContent.map((item) => getSubMenu(item))}</Nav>
        <MenuContent className="section-margin" />
      </Navbar.Collapse>
    </Navbar>
  );
};

HapprMenu.defaultProps = {
  hamburger: false,
};

export default HapprMenu;
