// @ts-nocheck
import { useDispatch } from "react-redux";
import { Amplify } from "aws-amplify";
import { gsap } from "gsap";
import { lazy, useEffect, useLayoutEffect, useState } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ApolloProvider } from "@apollo/client";
import { useLocation } from "react-router";

import dsnEffect, {
  fade,
  moveSection,
  textAnimation,
} from "./animation/DsnEffect";
import { setThemeMode } from "./redux/reducers/themeModeSlice";

import awsExports from "./aws-exports";
import client from "./helpers/apollo-client";

import "bootstrap/dist/css/bootstrap-grid.css";
import "swiper/css/pagination";
import "./assets/sass/style.scss";
import { getCookie } from "./utils/utilities";

const ModeSwitch = lazy(() => import("./components/thememode/ModeSwitch"));
const GoogleTagManager = lazy(() => import("./helpers/google-tag-manager"));

import Router from "./router/Router";
import Layout from "./layout/Layout";
import Footer from "./components/footer/Footer";
import useEarlyAccess from "./hooks/useEarlyAccess";

Amplify.configure(awsExports);

function App() {
  const [mode, setMode] = useState("light");
  const dispatch = useDispatch();
  const location = useLocation();
  // const earlyAccessCookie = getCookie("earlyAccess");
  const hasAccess = useEarlyAccess();

  const onSelectMode = (mode: string) => {
    setMode(mode);
    document.body.classList.toggle("v-dark", mode === "dark");
    // Update the global state
    dispatch(setThemeMode(mode));
  };

  // Add eventListener for mode detection
  useEffect(() => {
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
    const modeChangeListener = (e: MediaQueryListEvent) =>
      onSelectMode(e.matches ? "dark" : "light");

    mediaQueryList.addEventListener("change", modeChangeListener);
    onSelectMode(mediaQueryList.matches ? "dark" : "light");

    return () => {
      mediaQueryList.removeEventListener("change", modeChangeListener);
    };
  }, []);

  // Register GSAP plugins and effects
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    dsnEffect.registerEffect(moveSection, fade, textAnimation);

    gsap.config({
      nullTargetWarn: false,
    });
    gsap.defaults({
      ease: "none",
      duration: 1,
      overwrite: "auto",
    });
  }, []);

  return (
    <Layout
      className={`background-main ${mode === "light" ? "v-light" : "v-dark"}`}
      tag="main"
    >
      <ApolloProvider client={client}>
        <Router />
      </ApolloProvider>
      <GoogleTagManager
        containerId={process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || ""}
      />
      {hasAccess && <ModeSwitch mode={mode} onSelectMode={onSelectMode} />}
    </Layout>
  );
}

export default App;
