import { useEffect, useState } from "react";
import { getCookie, setCookie } from "../utils/utilities";
import { useLocation } from "react-router-dom";

const useEarlyAccess = () => {
  const location = useLocation();

  // Function to check if access is granted
  const checkAccess = () => {
    const earlyAccessCookie = getCookie("earlyAccess") === "true";
    const searchParams = new URLSearchParams(location.search);
    const accessParam = searchParams.get("access") === "early2024";

    // Set the cookie if access parameter is present
    if (accessParam) {
      setCookie("earlyAccess", "true", 7); // valid for 7 days
    }

    return earlyAccessCookie || accessParam;
  };

  const [hasAccess, setHasAccess] = useState(checkAccess());

  useEffect(() => {
    // Update access state if the location changes
    setHasAccess(checkAccess());
  }, [location]);

  //   return hasAccess;
  return true; //   Access enable
};

export default useEarlyAccess;
