// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../utils/client";
import { formatCategoryKey } from "../../utils/utilities";

// Define an async thunk to fetch posts by category slugs
export const fetchPosts = createAsyncThunk(
  "posts/fetchPosts",
  async (categorySlugs: string[], { rejectWithValue }) => {
    try {
      // GROQ query to fetch posts by category slugs and sort by published date
      const CONTENT_QUERY = `*[_type == "post" && references(*[_type == "category" && slug.current in $categorySlugs]._id)] | order(publishedAt desc) {
        title,
        slug,
        publishedAt,
        excerpt,
        mainImage {
          asset-> {
            url
          }
        },
        categories[]-> {
          title       
        },
      }`;

      const data = await client.fetch(CONTENT_QUERY, { categorySlugs });
      return { categorySlugs, data };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Initial state for the posts slice
const initialState = {
  postsByCategory: {},
  loading: false,
  error: null,
};

// Create a slice
const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // When the request is pending
      .addCase(fetchPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // When the request is fulfilled
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.loading = false;
        const { categorySlugs, data } = action.payload;

        // Combine the category slugs into a single key
        const combinedKey = formatCategoryKey(categorySlugs);

        // Store the posts under the combined category key
        state.postsByCategory[combinedKey] = data;
      })
      // When the request is rejected
      .addCase(fetchPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default postsSlice.reducer;
