import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import "./style.scss";
import Dropdown from "./Dropdown";
import Toggle from "./Toggle";
import { Link } from "react-router-dom";
import useEffectScroll from "../../hooks/useEffectScroll";
import { dsnCN } from "../../hooks/helper";
import { useSelector } from "react-redux";

const Navbar = ({ children, textOpen, textMenu, textClose, hamburger }) => {
  const nav = useRef();
  const [typeNave, setTypeNave] = useState("");
  const [reserved, setReserved] = useState(false);
  const [userEffectScrollState, setUserEffectScrollState] = useState("");
  const themeModeState = useSelector((state) => state.themeMode);
  let scrDown = 0;

  //This function helps toggling the colors on the top navigation
  const applyOrRemoveCSSClass = (element, action, cssClass) => {
    action === "add"
      ? element.classList.add(cssClass)
      : element.classList.remove(cssClass);
  };

  useEffectScroll((e, x, y) => {
    if (y > 170) {
      if (scrDown < y) {
        if (userEffectScrollState !== "nav-bg-and-hide-nav-added") {
          nav.current.classList.add("nav-bg", "hide-nav");
          //Adjust colors to top nav elements
          if (
            themeModeState.current === "light" &&
            !nav.current
              .querySelector(".navbar-toggle")
              .classList.contains("open")
          ) {
            applyOrRemoveCSSClass(
              nav.current.querySelector(".toggle-text"),
              "add",
              "apply-heading-color-as-color"
            );
            //Adjust color of Hamburger icon lines
            let menuLines = nav.current.querySelectorAll(".toggle-line");
            Object.keys(menuLines).map((key) =>
              applyOrRemoveCSSClass(
                menuLines[key],
                "add",
                "apply-heading-color-as-background-color"
              )
            );
          }
          setUserEffectScrollState("nav-bg-and-hide-nav-added");
        }
      } else {
        if (userEffectScrollState !== "hide-nav-removed") {
          nav.current.classList.remove("hide-nav");
          setUserEffectScrollState("hide-nav-removed");
        }
      }
    } else {
      if (userEffectScrollState !== "nav-bg-and-hide-nav-removed") {
        nav.current.classList.remove("nav-bg", "hide-nav");
        if (
          themeModeState.current === "light" &&
          !nav.current
            .querySelector(".navbar-toggle")
            .classList.contains("open")
        ) {
          //Adjust colors of menu text color
          applyOrRemoveCSSClass(
            nav.current.querySelector(".toggle-text"),
            "remove",
            "apply-heading-color-as-color"
          );
          //Adjust color of Hamburger icon lines
          let menuLines = nav.current.querySelectorAll(".toggle-line");
          Object.keys(menuLines).map((key) =>
            applyOrRemoveCSSClass(
              menuLines[key],
              "remove",
              "apply-heading-color-as-background-color"
            )
          );
        }
        setUserEffectScrollState("nav-bg-and-hide-nav-removed");
      }
    }

    scrDown = y;
  });

  const cutterElement = () => {
    nav.current.querySelectorAll("ul").forEach((item, index) => {
      item.style.setProperty("--dsn-li-name", "dsn" + index);
      Object.keys(item.children).forEach(($key) => {
        item.children[$key].style.setProperty("--dsn-li-index", $key);
      });
    });
  };

  const TransEnd = () => {
    if (reserved) {
      nav.current.querySelector(".primary-nav").classList.add("open");
      if (themeModeState.current === "light") {
        //Adjust colors when opening menu
        applyOrRemoveCSSClass(
          nav.current.querySelector(".toggle-text"),
          "add",
          "apply-heading-color-as-color"
        );
        //Adjust color of Hamburger icon lines
        let menuLines = nav.current.querySelectorAll(".toggle-line");
        Object.keys(menuLines).map((key) =>
          applyOrRemoveCSSClass(
            menuLines[key],
            "add",
            "apply-heading-color-as-background-color"
          )
        );
        //Adjust logo color to dark since you're in light theme
        nav.current.querySelector(".logo-light").classList.add("hideLogo");
        nav.current.querySelector(".logo-dark").classList.add("showLogo");
      }
    }
  };

  const removeOpenMenu = useCallback(() => {
    nav.current
      .querySelectorAll("ul")
      .forEach((item) => item.classList.remove("open"));

    //Adjust colors when menu closing
    if (themeModeState.current === "light") {
      if (!nav.current.classList.contains("nav-bg")) {
        applyOrRemoveCSSClass(
          nav.current.querySelector(".toggle-text"),
          "remove",
          "apply-heading-color-as-color"
        );
        //Adjust color of Hamburger icon lines when menu closed
        let menuLines = nav.current.querySelectorAll(".toggle-line");
        Object.keys(menuLines).map((key) =>
          applyOrRemoveCSSClass(
            menuLines[key],
            "remove",
            "apply-heading-color-as-background-color"
          )
        );
      }
      //Remove classes from menu open state
      nav.current.querySelector(".logo-light").classList.remove("hideLogo");
      nav.current.querySelector(".logo-dark").classList.remove("showLogo");
    }
  }, [themeModeState]);

  // only runs after first render
  useLayoutEffect(() => {
    const resizeNav = () => {
      if (window.innerWidth > 991) {
        setTypeNave("");
        removeOpenMenu();
        setReserved(false);
      } else setTypeNave("dsn-hamburger");
    };

    if (!hamburger) {
      window.addEventListener("resize", resizeNav);
      resizeNav();
    } else {
      setTypeNave("dsn-hamburger");
    }

    cutterElement();

    return () => {
      window.removeEventListener("resize", resizeNav);
    };
  }, [hamburger]); 

  return (
    <header
      className={dsnCN("site-header container-fluid", typeNave)}
      ref={nav}
    >
      {children}
      {typeNave && (
        <Toggle
          textOpen={textOpen}
          textMenu={textMenu}
          textClose={textClose}
          targetNav={nav}
          reserved={reserved}
          setReserved={setReserved}
          removeOpenMenu={removeOpenMenu}
        />
      )}
      <div className="bg background-section" />
      <div className="bg background-main" onTransitionEnd={TransEnd} />
    </header>
  );
};

Navbar.defaultProps = {
  textOpen: "Open",
  textMenu: "Menu",
  textClose: "Close",
};

const handleClickCloseMenu = (e) => {
  e.stopPropagation();
  const parent = e.currentTarget.closest(".site-header");
  if (!parent) return;

  if (
    !parent.classList.contains("dsn-hamburger") ||
    !parent.classList.contains("dsn-open")
  )
    return;

  const navToggle = parent.querySelector(".navbar-toggle");
  if (navToggle) navToggle.click();
};

export const Brand = ({ children, ...restProps }) => {
  return (
    <div className="main-logo" onClick={handleClickCloseMenu}>
      <Link {...restProps}>{children}</Link>
    </div>
  );
};

Navbar.Brand = Brand;

export const Collapse = ({ children, cover }) => {
  return (
    <nav className="main-navigation">
      {cover && (
        <div className="menu-cover-title header-container dsn-container">
          {cover}
        </div>
      )}
      {children}
    </nav>
  );
};
Navbar.Collapse = Collapse;

export const Nav = ({ children, className, ...restProps }) => {
  return (
    <ul className={dsnCN("primary-nav h2", className)} {...restProps}>
      {children}{" "}
    </ul>
  );
};

export const ItemLink = ({ children, ...restProps }) => {
  return (
    <li className="nav-item" onClick={handleClickCloseMenu}>
      <Link {...restProps}>
        <span className="overflow">{children}</span>
      </Link>
    </li>
  );
};
Nav.Link = ItemLink;

Dropdown.defaultProps = { textBack: "Back" };
Nav.Dropdown = Dropdown;

export { Dropdown };

export default Navbar;
