// import React from "react";
import { Link } from "react-router-dom";
import { dsnCN } from "../../../hooks/helper";
import { trackStartConversationClick } from "../../../utils/trackingEvents";

function MenuContent({ className }) {
  const handleClickCloseMenu = (e) => {
    e.stopPropagation();
    const parent = e.currentTarget.closest(".site-header");
    if (!parent) return;

    if (
      !parent.classList.contains("dsn-hamburger") ||
      !parent.classList.contains("dsn-open")
    )
      return;

    const navToggle = parent.querySelector(".navbar-toggle");
    if (navToggle) navToggle.click();
  };

  // const socialData = [
  //   // { link: "#0", name: "Dribbble." },
  //   // { link: "#0", name: "Behance." },
  //   { link: "#0", name: "Linkedin." },
  //   { link: "#0", name: "Twitter." },
  // ];
  return (
    <div
      className={dsnCN(
        "container-content  d-flex flex-column justify-content-center",
        className
      )}
    >
      <div className="nav__info">
        {/* <div className="nav-content">
          <h5 className="sm-title-block mb-10">Headquarters</h5>
          <p>
            55 Village Centre Place
            <br />
            Mississauga, ON L4Z 1V9
            <br />
            Canada
          </p>
        </div> */}
        <div className="nav-content mt-30">
          <h5 className="sm-title-block mb-10">Contact Us</h5>
          <p className="links over-hidden">
            <Link
              to="/contact"
              className="link-hover"
              onClick={(e) => {
                handleClickCloseMenu(e);
                trackStartConversationClick(); // Track event here
              }}
            >
              Click to Start a Conversation
            </Link>
          </p>
          {/* <p className="links over-hidden">
            <a
              className="link-hover"
              href="/contact"
              data-hover-text="Get in touch"
            >
              Click to Start a Conversation
            </a>
          </p> */}
          <br />
          {/* <p className="links over-hidden">
            <a
              className="link-hover"
              href="mailto:hello@happr.ai"
              data-hover-text="hello@happr.ai"
            >
              hello@happr.ai
            </a>
          </p> */}
          {/* <p className="links over-hidden mb-1">
            <a
              className="link-hover"
              href="tel:+12265776201"
              data-hover-text="+1 (226) 577-6201"
            >
              International: +1 (226) 577-6201
            </a>
          </p>
          <p className="links over-hidden mb-1">
            <a
              className="link-hover"
              href="tel:+12265776201"
              data-hover-text="+1 (226) 577-6201"
            >
              USA & Canada: +1 (226) 577-6201
            </a>
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default MenuContent;
