// @ts-nocheck
import React from "react";
import styled from "styled-components";
import brandLight from "../../assets/img/logo.png";
import brandDark from "../../assets/img/logo-dark.png";

const MainBrand = styled.div<{ width: string; height: string }>`
  img {
    width: ${(props: any) => props.width};
    max-width: 100%;
    height: ${(props: any) => props.height};
  }

  .v-light,
  .site-header:not(.nav-bg) &,
  & {
    .logo-light {
      display: block;
    }
    .logo-dark {
      display: none;
    }
  }

  .v-light,
  .site-header:has(.nav-bg) &,
  & {
    .logo-dark {
      display: block;
    }
    .logo-light {
      display: none;
    }
  }

  .v-dark & {
    .logo-dark {
      display: none;
    }
    .logo-light {
      display: block;
    }
  }
`;

interface BrandProps {
  width?: string;
  height?: string;
  alt?: string;
}

const Brand: React.FC<BrandProps> = ({
  width = "100px",
  height = "auto",
  alt = "HAPPR.AI",
}) => {
  return (
    <MainBrand className="main-brand" width={width} height={height}>
      <img
        className="logo-light"
        src={brandLight}
        alt={`${alt} - logo light`}
        width={width}
        height={height}
      />
      <img
        className="logo-dark"
        src={brandDark}
        alt={`${alt} - logo dark`}
        width={width}
        height={height}
      />
    </MainBrand>
  );
};

export default React.memo(Brand);
