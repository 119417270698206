import SegmentAnalytics from "./segment";

export const trackStartConversationClick = () => {
  SegmentAnalytics.track("Start Conversation Clicked", {
    category: "Contact",
    label: "Start a Conversation from Menu",
  });
};


export const trackLinkClick = (item:any) => {
  SegmentAnalytics.track("Clicked on", {
    title: item.title,
    link: item.link,
  });
};

// Segment filters