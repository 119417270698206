import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

import App from "./App";
import "./index.css";

const rootElement = document.getElementById("root") as HTMLElement;
createRoot(rootElement).render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
