import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import smoothScrollbarReducer from "./reducers/smoothScrollbarSlice";
import themeModeReducer from "./reducers/themeModeSlice";
import homeReducer from "./reducers/homeSlice";
import modalReducer from "./reducers/modalSlice";
import postsSlice from "./reducers/postsSlice";

export const store = configureStore({
  reducer: {
    scrollbar: smoothScrollbarReducer,
    themeMode: themeModeReducer,
    home: homeReducer,
    modal: modalReducer,
    posts: postsSlice,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type StoreDispatch = typeof store.dispatch;
