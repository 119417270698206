import React, { useLayoutEffect, useRef } from "react";
import SmoothScrollbar from "./SmoothScrollbar";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useDispatch } from "react-redux";
import { setScrollbar } from "../redux/reducers/smoothScrollbarSlice";

interface MainScrollBarProps {
  children: React.ReactNode;
  tag?: React.ElementType;
}

function MainScrollBar({
  children,
  tag: Tag = "div",
  ...restProps
}: MainScrollBarProps): JSX.Element {
  const scrollbar = useRef<{
    target: null | any;
    ref: null | HTMLElement;
  }>({
    target: null,
    ref: null,
  });
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    ScrollTrigger.defaults({
      scroller: scrollbar.current.ref,
    });
    scrollbar.current.target?.addListener(() => ScrollTrigger.refresh());
    dispatch(setScrollbar(scrollbar.current.target));

    return () => {
      ScrollTrigger.defaults({
        scroller: null,
      });
      scrollbar.current.target?.destroy();
    };
  }, []);

  return (
    <SmoothScrollbar
      id="dsn-scrollbar"
      tag={Tag}
      height="100vh"
      option={{ damping: 0.08 }}
      refTarget={(target: any, ref: any) =>
        (scrollbar.current = { target, ref })
      }
      {...restProps}
    >
      {children}
    </SmoothScrollbar>
  );
}

export default MainScrollBar;
