import MainScrollBar from "./MainScrollBar";
import HapprMenu from "../components/header/menu/HapprMenu";
import useEarlyAccess from "../hooks/useEarlyAccess";
import type { SmoothScrollbarProps } from "./SmoothScrollbar";

interface LayoutProps extends SmoothScrollbarProps {
  className: string;
  activeScrollbar?: boolean;
}

function Layout({
  className,
  children,
  tag: Tag = "div",
  activeScrollbar,
  ...restProps
}: LayoutProps) {
  const hasAccess = useEarlyAccess();
  return (
    <Tag id="main_layout" className={className}>
      {hasAccess ? <HapprMenu hamburger />:null}
      {activeScrollbar ? (
        <MainScrollBar {...restProps}>{children}</MainScrollBar>
      ) : (
        children
      )}
    </Tag>
  );
}

export default Layout;
