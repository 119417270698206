// @ts-nocheck
import React, { useState, useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { RootState } from "../redux/store";
import SegmentAnalytics from "../utils/segment";
import useEffectLocation from "../hooks/useEffectLocation";
import useEarlyAccess from "../hooks/useEarlyAccess";
import "./style.scss";

// Lazy loaded components
const About = React.lazy(() => import("../views/About"));
const Contact = React.lazy(() => import("../views/Contact"));
const ComingSoon = React.lazy(() => import("../views/ComingSoon"));
const Home = React.lazy(() => import("../views/Home"));
const CaseStudies = React.lazy(() => import("../views/case-studies"));
const CaseStudiesDetails = React.lazy(
  () => import("../views/case-studies/Slug")
);
const Chatbox = React.lazy(() => import("../views/services/Chatbox"));
const DataArtificialIntelligence = React.lazy(
  () => import("../views/services/DataArtificialIntelligence")
);
const ConversationalAIApplications = React.lazy(
  () => import("../views/services/ConversationalAIApplications")
);
const DigitalStrategyConsulting = React.lazy(
  () => import("../views/services/DigitalStrategyConsulting")
);
const TechnologyEngineering = React.lazy(
  () => import("../views/services/TechnologyEngineering")
);
const PlatformingCloudServices = React.lazy(
  () => import("../views/services/PlatformingCloudServices")
);
const Purpose = React.lazy(() => import("../views/Purpose"));
const RPDP = React.lazy(() => import("../views/solutions/RPDP"));
const ServicesIndex = React.lazy(() => import("../views/services/index"));
const Reports = React.lazy(() => import("../views/reports"));
const ReportDetails = React.lazy(() => import("../views/reports/Slug"));
const Privacy = React.lazy(() => import("../views/Privacy"));
const NewsAndInsights = React.lazy(
  () => import("../views/news-and-insights/NewsAndInsights")
);
const InsightsDetails = React.lazy(
  () => import("../views/news-and-insights/InsightsDetails")
);
const NotFound = React.lazy(() => import("../views/NotFound"));

const Router: React.FC = () => {
  const [transPage, setTransPage] = useState("in");
  const location = useLocation();
  const scrollbar = useSelector<RootState["scrollbar"]>(
    (state) => state.scrollbar
  ) as React.MutableRefObject<null | any>;

  const hasAccess = useEarlyAccess();

  const loc = useEffectLocation((l) => {
    setTransPage("out");
  });

  // Check if the early access cookie exists

  const onAnimateEnd = () => {
    if (transPage !== "out") return;
    scrollbar.current?.scrollTo(0, 0, 0);
    window.scrollTo({ left: 0, top: 0 });
    setTransPage("in");

    // Segment page tracking after animation finishes
    const pageName = location.pathname;
    const pageCategory = getCategoryFromPath(location.pathname);
    if (hasAccess) {
      SegmentAnalytics.page(pageCategory, pageName, {
        title: document.title,
        url: window.location.href,
        path: location.pathname,
        referrer: document.referrer,
      });
    }
  };

  useEffect(() => {
    setTransPage("out");
  }, [location]);

  // Helper function to determine page category based on pathname
  const getCategoryFromPath = (path: string) => {
    if (path.includes("/services")) return "Services";
    if (path.includes("/case-studies")) return "Case Studies";
    if (path.includes("/reports")) return "Reports";
    return "General";
  };

  return (
    <div
      id="dsn-content"
      className={`dsn-transition-page dsn-animate-${transPage}`}
      onAnimationEnd={onAnimateEnd}
    >
      <Suspense fallback={<div className="background-main h-100-v" />}>
        <Routes location={loc}>
          {hasAccess ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/services" element={<ServicesIndex />} />
              <Route
                path="/services/data-artificial-intelligence"
                element={<DataArtificialIntelligence />}
              />
              <Route
                path="/services/conversational-ai-applications"
                element={<ConversationalAIApplications />}
              />
              <Route
                path="/services/digital-strategy-consulting"
                element={<DigitalStrategyConsulting />}
              />
              <Route
                path="/services/technology-engineering"
                element={<TechnologyEngineering />}
              />
              <Route
                path="/services/platforming-cloud-services"
                element={<PlatformingCloudServices />}
              />
              <Route path="/chatbots" element={<Chatbox />} />
              <Route
                path="/rpdp-rapid-product-development-platform"
                element={<RPDP />}
              />
              <Route path="/purpose" element={<Purpose />} />
              <Route path="/case-studies" element={<CaseStudies />} />
              <Route
                path="/case-studies/:slug"
                element={<CaseStudiesDetails />}
              />
              <Route path="/reports" element={<Reports />} />
              <Route path="/reports/:slug" element={<ReportDetails />} />
              <Route path="/news-and-insights" element={<NewsAndInsights />} />
              <Route
                path="/news-and-insights/:slug"
                element={<InsightsDetails />}
              />
              <Route path="*" element={<NotFound />} />
            </>
          ) : (
            <>
              <Route path="/coming-soon" element={<ComingSoon />} />
              <Route path="*" element={<Navigate to="/coming-soon" />} />
            </>
          )}
        </Routes>
      </Suspense>
    </div>
  );
};

export default React.memo(Router);
