export function formatPublishedAt(publishedAt: string): string {
  // Create a new Date object from the publishedAt string
  const date = new Date(publishedAt);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  // Use Intl.DateTimeFormat to format the date in a readable way
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}

export const formatCategoryKey = (item: string[]) =>
  item?.map((name) => name).join("+");

export const parseCategoryKey = (key: string) => key.split("+");

// Function to convert pathname to readable format
export const getReadablePathname = (pathname: any) => {
  // Check if pathname is just the root '/', return 'Home' for the homepage
  if (pathname == "/" || pathname == "") {
    return "Home";
  }

  // Otherwise, format the pathname to a readable format
  return pathname
    .replace(/^\//, "") // Remove leading slash
    .replace(/-/g, " ") // Replace hyphens with spaces
    .split(" ") // Split into words
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join the words back into a string
};

// Function to set a cookie
export const setCookie = (name: string, value: string, days: number) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

// Function to get a cookie by name
export const getCookie = (name: string) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length); // Remove leading spaces
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

// Function to delete a cookie
export const deleteCookie = (name: string) => {
  document.cookie = name + "=; Max-Age=-99999999; path=/;";
};

export const getCategoryTitles = (posts: any[], slug: string) => {
  if (posts.length > 0) {
    const categoryTitle = posts[0]?.categories?.[0]?.title;
    return categoryTitle || slug;
  }

  // Format the slug if no posts are found
  return slug.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
};
