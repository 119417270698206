// @ts-nocheck
import { createClient } from "@sanity/client";

export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: "production",
  apiVersion: "2024-03-11",
  useCdn: false,
});

export const sanityImageUrl = (ref) => {
  const projectId = process.env.REACT_APP_SANITY_PROJECT_ID;
  const dataset = "production";

  const [, assetId, dimensions, format] = ref.split("-");

  return `https://cdn.sanity.io/images/${projectId}/${dataset}/${assetId}-${dimensions}.${format}`;
};
