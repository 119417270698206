// @ts-nocheck
import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";

interface SegmentAnalyticsType {
  track: (event: string, properties?: Record<string, any>) => void;
  identify: (userId: string, traits?: Record<string, any>) => void;
  page?: (
    category: string,
    name?: string,
    properties?: Record<string, any>
  ) => void;
}

// Convert the env variable to a boolean value
const isAnalyticsEnabled =
  process.env.REACT_APP_ENABLE_SEGMENT_ANALYTICS === "true";

let SegmentAnalytics: SegmentAnalyticsType;

if (isAnalyticsEnabled) {
  SegmentAnalytics = Analytics({
    app: process.env.REACT_APP_INTERCOM_APP_ID,
    plugins: [
      segmentPlugin({
        writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
      }),
    ],
  });
} else {
  // Fallback stubs for disabled analytics
  SegmentAnalytics = {
    track: () => {},
    identify: () => {},
    page: () => {},
  };
}

export default SegmentAnalytics;
