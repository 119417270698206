// @ts-ignore
import { ReactComponent as ArrowLeft } from "../../../assets/img/left-arrow.svg";
import dsnSplitting from "../../../hooks/Spltting";
import React, { useEffect, useRef, MouseEvent } from "react";

interface NavDropdownProps {
  children: React.ReactNode;
  name: string;
  textBack?: string;
  [restProps: string]: any;
}

const NavDropdown: React.FC<NavDropdownProps> = ({
  children,
  name,
  textBack,
  ...restProps
}) => {
  const refName = useRef<HTMLSpanElement>(null);
  const refBack = useRef<HTMLSpanElement>(null);

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const openElement = (e.currentTarget as HTMLElement).closest(".open");
    if (openElement) {
      openElement.classList.remove("open");
    }
  };

  const handleClickSubMenu = (e: MouseEvent) => {
    handleClick(e);
    const ulElement = (e.currentTarget as HTMLElement).querySelector("ul");
    if (ulElement) {
      ulElement.classList.add("open");
    }
  };

  const handleClickBackMenu = (e: MouseEvent) => {
    handleClick(e);
    const ulElement = (e.currentTarget as HTMLElement)
      .closest("ul")
      ?.closest("li")
      ?.closest("ul");
    if (ulElement) {
      ulElement.classList.add("open");
    }
  };

  useEffect(() => {
    if (refName.current) {
      dsnSplitting.Char(refName.current);
    }
    if (refBack.current) {
      dsnSplitting.Char(refBack.current);
    }
  }, []);

  return (
    <li className="nav-item has-sub-menu" onClick={handleClickSubMenu}>
      <a href="#0" {...restProps}>
        <span className="overflow">{name}</span>
      </a>
      <ul className="sub-menu h3">
        <li className="dsn-back" onClick={handleClickBackMenu}>
          <span>
            <ArrowLeft />
            <span className="text-toggle-back">
              <span className="text-name" ref={refName}>
                {name}
              </span>
              <span className="text-back" ref={refBack}>
                {textBack}
              </span>
            </span>
          </span>
        </li>
        {children}
      </ul>
    </li>
  );
};

export default NavDropdown;
